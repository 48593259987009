<template lang="pug">
    canvas
</template>

<script>
import * as twgl from 'twgl.js'
import m3 from '@/plugins/m3.js'
import vertex from 'raw-loader!@/assets/shaders/ImageParallaxVertex.glsl'
import fragment from 'raw-loader!@/assets/shaders/ImageParallaxFragment.glsl'

export default {
    props: ["image", "mask"],
    mounted() {
        const gl = this.$el.getContext("webgl")
        if(!gl) return

        let originalImage = { width: 1, height: 1 }
        const originalTexture = twgl.createTexture(gl, {
            src: this.image,
            crossOrigin: ''
        }, (err, texture, source) => {
            originalImage = source
        })

        const mapTexture = twgl.createTexture(gl, {
            src: this.mask,
            crossOrigin: ''
        })

        var programInfo = twgl.createProgramInfo(gl, [vertex, fragment])
        var bufferInfo = twgl.primitives.createXYQuadBufferInfo(gl)

        const mouse = [0, 0]
        document.addEventListener('mousemove', (event) => {
            mouse[0] = (event.clientX / gl.canvas.clientWidth  * 2 - 1) * 0.05;
            mouse[1] = (event.clientY / gl.canvas.clientHeight * 2 - 1) * 0.05;
        })

        requestAnimationFrame(render)
        function render(time) {
            time *= 0.003
            
            twgl.resizeCanvasToDisplaySize(gl.canvas)

            gl.viewport(0, 0, gl.canvas.width, gl.canvas.height)

            gl.clearColor(0, 0, 0, 0)
            gl.clear(gl.COLOR_BUFFER_BIT)

            gl.useProgram(programInfo.program)


            twgl.setBuffersAndAttributes(gl, programInfo, bufferInfo)

            const canvasAspect = gl.canvas.clientWidth / gl.canvas.clientHeight
            const imageAspect = originalImage.width / originalImage.height

            let horizontalDrawAspect = imageAspect / canvasAspect
            let verticalDrawAspect = -1
            if (horizontalDrawAspect < 1) {
                verticalDrawAspect /= horizontalDrawAspect
                horizontalDrawAspect = 1
            }
            const mat = m3.scaling(horizontalDrawAspect, verticalDrawAspect)
            
            twgl.setUniforms(programInfo, {
                u_matrix: mat,
                u_originalImage: originalTexture,
                u_mapImage: mapTexture,
                u_mouse: mouse,
            })

            twgl.drawBufferInfo(gl, bufferInfo)

            requestAnimationFrame(render)
        }
    }
}
</script>

<style lang="stylus" scoped>
    canvas
        position absolute
        top 0
        left @top
        width 100%
        height @width
</style>